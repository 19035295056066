import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import CurrencyInput from "react-currency-input-field";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";

function AbonosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const PASSWORD_CAMBIA_PRECIO = process.env.REACT_APP_PASSWORD_CAMBIA_PRECIO;
  const URL_NOTAS_CARGO = process.env.REACT_APP_URL_NOTAS_CARGO;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 60);
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedClienteNombre, setSelectedClienteNombre] = useState("");
  const [selectedClienteSaldo, setSelectedClienteSaldo] = useState("");
  const [selectedClienteTelefono, setSelectedClienteTelefono] = useState("");
  const [fecha, setFecha] = useState(hoy);
  const [cortesia, setCortesia] = useState(0);
  const [efectivo, setEfectivo] = useState(0);
  const [tdc, setTDC] = useState(0);
  const [transfer, setTransfer] = useState(0);
  const [fechaTransfer, setFechaTransfer] = useState("NA");
  const [referencia, setReferencia] = useState("NA");
  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [cargos, setCargos] = useState([]);
  const [total, setTotal] = useState(0);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());
  const [concepto, setConcepto] = useState("Abono");
  const [observaciones, setObservaciones] = useState("NA");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [inputFields, setInputFields] = useState([]);
  const [inputFieldsNC, setInputFieldsNC] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);
  const [password, setPassword] = useState("");

  useMemo(() => {

    axios
    .get(`${URL_AREAS}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allAreas = response.data;
      setAreas(allAreas);
    })
    .catch((err) => {
      console.log(err);
    });


    axios
    .get(`${URL_CLIENTES}Activos`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allClientes = response.data;
      setClientes(allClientes);
    })
    .catch((err) => {
      console.log(err);
    });

  if(selectedCliente){
    axios
      .get(`${URL_SURTIDOS}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        // setSelectedArea(allCargos[0].areas[0]._id)
        let arrayTabla = allCargos
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.total_general,
              saldoInicial: a.saldo,
              saldo: a.saldo,
              comTDC:0,
              nota: a.idSurtido,
              efectivo: 0,
              tdc: 0,
              terminal: "NA",
              referencia: "NA",
              transfer: 0,
              fechaTransfer: "NA",
              destino: "NA",
              cortesia: 0,
              totalAbono: 0,
              areas: a.areas[0]._id,
              clientes: a.clientes[0]._id,
              areasName: a.areas[0].name,
              movimiento: "Nota",
              // user: a.user[0].nombre + " " + a.user[0].apellido,
              clientesNombre: a.clientes[0].nombre_comercial,
              clientesTelefono: a.clientes[0].telefono,
              numero: a.idSurtido,
              idPDFOC: a._id,
              comisionTDC: a.comisionTDC,
              comisionFlete: a.comisionFlete,
              impuestos: a.impuestos,
              area: a.areas[0].name,
              descuento:a.descuento,
              descuentoNumero: (parseFloat(a.subTotal) * parseFloat(a.descuento))/100
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setCargos(dataFinal);
        setInputFields(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_NOTAS_CARGO}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        // setSelectedArea(allCargos[0].clientes[0].areas[0]._id)
        let arrayTabla = allCargos
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.importe,
              saldoInicial: a.saldo,
              saldo: a.saldo,
              comTDC:0,
              nota: a.idNotaCargo,
              efectivo: 0,
              tdc: 0,
              terminal: "NA",
              referencia: "NA",
              transfer: 0,
              fechaTransfer: "NA",
              destino: "NA",
              cortesia: 0,
              totalAbono: 0,
              areas: a.clientes[0].areas[0]._id,
              clientes: a.clientes[0]._id,
              areasName: a.clientes[0].areas[0].name,
              movimiento: "Nota de Cargo",
              comisionTDC: a.comisionTDC,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setInputFieldsNC(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
    }

  }, [selectedCliente, user]);

console.log("hola", startDate)
async function PDFOC() {
  try {
    const response = await axios.get(`${URL_ESTADO_CUENTA}Agrupado/${selectedCliente}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    });

    const movimientos = response.data;
    const startDateObj = new Date(startDate);
    let saldoInicial = 0;

    // Calcular saldo inicial antes de la fecha de inicio
    movimientos.forEach((mov) => {
      const fechaMovimiento = new Date(mov.fecha);
      if (fechaMovimiento < startDateObj) {
        saldoInicial += mov.cargo - mov.abono;
      }
    });

    // Variables para los cálculos
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let saldoParcial = saldoInicial;

    // Función para formatear moneda
    const formatCurrency = (value) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
      }).format(value);

    // Procesar movimientos desde la fecha de inicio
    const data = movimientos
      .filter((mov) => new Date(mov.fecha) >= startDateObj)
      .map((mov) => {
        totalCargosEdoCte += mov.cargo;
        totalAbonosEdoCte += mov.abono;
        saldoParcial += mov.cargo - mov.abono;

        return [
          mov.fecha,
          mov.movimiento,
          mov.numero,
          formatCurrency(mov.cargo),
          formatCurrency(mov.abono),
          mov.referencia,
          formatCurrency(saldoParcial),
        ];
      });

    const dataPDFLimpia = [
      [
        "Saldo Inicial",
        "",
        "",
        "",
        "",
        "",
        formatCurrency(saldoInicial),
      ],
      ...data,
    ];

    // Crear el documento PDF
    const doc = new jsPDF("landscape", "mm", "a4", true);
    const img1 = new Image();
    img1.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img1, "png", 230, 30, 45, 15);

    const img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);

    doc.text(`Estado de Cuenta desde ${startDate}`, 20, 30);
    doc.text(`Cliente: ${selectedClienteNombre}`, 20, 37);
    const pagoTotal = parseFloat(efectivo) + parseFloat(tdc) + parseFloat(transfer);
    doc.text(`Registramos un pago por ${formatCurrency(pagoTotal)}`, 20, 44);

    doc.autoTable({
      head: [
        ["Fecha", "Movimiento", "Numero", "Cargos", "Abonos", "Referencia", "Saldo"],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          formatCurrency(saldoInicial),
        ],
        [
          "",
          "",
          "",
          "",
          { content: "Cargos del Periodo", colSpan: 2, styles: { halign: "left" } },
          formatCurrency(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          { content: "Abonos del Periodo", colSpan: 2, styles: { halign: "left" } },
          formatCurrency(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          formatCurrency(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });

    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
  } catch (error) {
    console.error("Error generando el PDF:", error);
    alert("Ocurrió un error al generar el PDF. Por favor, inténtalo de nuevo.");
  }
}


  function savePago() {
    setValidaBoton(false);
    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará el pago",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
          if (efectivo > 0 || tdc > 0 || transfer > 0) {
            axios
              .post(
                URL_ABONOS,
                {
                  fecha,
                  total: parseFloat(parseFloat(efectivo) + parseFloat(tdc) + parseFloat(transfer)),
                  areas: AREAS_GENERAL,
                  clientes: selectedCliente,
                  efectivo: efectivo,
                  tdc: tdc,
                  transfer: transfer,
                  fechaTransfer: fechaTransfer,
                  cortesia: 0,
                  nomina:0,
                  destino: "Transfer",
                  terminal: "Taerjeta",
                  referencia: referencia,
                  codigoAgrupado,
                  comisionTDC: 0,
                  concepto,
                  observaciones,
                  condPago:"Credito",
                  anticipo: "No",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                PDFOC();
                Swal.fire("Good job!", "Creado con exito", "success");
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
                
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Falta llenar datos!",
            });
          }
      }
    });
  }



  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });


  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonos ? (
        <div className="card container col-sm-11">
          <h3 align="center">Captura un Pago</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setValue(selectedCliente);
                        setSelectedCliente(selectedCliente._id);
                        setSelectedClienteSaldo((selectedCliente.saldo).toFixed(2));
                        setSelectedClienteNombre(selectedCliente.nombre_comercial);
                        setSelectedClienteTelefono(selectedCliente.telefono);
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={1}>
              <Label>Saldo</Label>
              <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Saldo"
                defaultValue={0}
                decimalsLimit={2}
                value={selectedClienteSaldo}
                disabled
                className={`form-control`}
              />
            </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Concepto</Label>
                  <Input
                    type="select"
                    value={concepto}
                    onChange={(e) => {
                      setConcepto(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Concepto</option>
                    <option value="Abono">Abono</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>

              <Col md={3}>
                  <Label className="mr-sm-2">Observaciones</Label>
                  <Input
                    type="text"
                    value={observaciones}
                    onChange={(e) => {
                      setObservaciones(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <h4 align="right">
                    Total Pago{"  "}
                    <Badge
                      id="Total"
                      color="danger"
                      className="BadgeSize badge-pill"
                    >
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(parseFloat(parseFloat(efectivo) + parseFloat(tdc) + parseFloat(transfer)))}
                    </Badge>
                  </h4>
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Table striped borderless>
                  <thead>
                    <tr>
                      <th>Efectivo</th>
                      <th>TDC</th>
                      <th>Transfer</th>
                      <th>Fecha</th>
                      <th>Referencia</th>
                    </tr>
                  </thead>
                  <tbody>
              <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="efectivo"
                            value={efectivo}
                            onChange={(e) => {
                              setEfectivo(e.target.value);
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="tdc"
                            value={tdc}
                            onChange={(e) => {
                              setTDC(e.target.value);
                            }}
                          />
                        </td>

                        <td>
                          <Input
                            // bsSize="sm"
                            type="number"
                            min="0"
                            step="any"
                            name="transfer"
                            value={transfer}
                            onChange={(e) => {
                              setTransfer(e.target.value);
                            }}
                          />
                        </td>
                       
                        <td>
                        {transfer > 0 ? (
                            <Input
                            // bsSize="sm"
                            type="date"
                            name="fechaTransfer"
                            value={fechaTransfer}
                            onChange={(e) => {
                              setFechaTransfer(e.target.value);
                            }}
                            />
                          ) : (
                            <Input
                              // bsSize="sm"
                              type="text"
                              name="fechaTransfer"
                              value={fechaTransfer}
                              disabled
                            />
                          )}
                        </td>
                        <td>
                        {transfer > 0 ? (
                            <Input
                            // bsSize="sm"
                            type="text"
                            name="referencia"
                            value={referencia}
                            onChange={(e) => {
                              setReferencia(e.target.value);
                            }}
                            />
                          ) : (
                            <Input
                              // bsSize="sm"
                              type="text"
                              name="referencia"
                              value={referencia}
                              disabled
                            />
                          )}
                        </td>

                  </tbody>
                </Table>

              </Row>
            </FormGroup>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={savePago}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/ListadoAbonos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

        </div>
      ) : undefined}
      
    </>
  );
}

export default AbonosCreate;
