import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";

function ListadoVentas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;
  const URL_DEPARTAMENTOS_CLIENTES =
    process.env.REACT_APP_URL_DEPARTAMENTOS_CLIENTES;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(endDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");
  const [articulos, setArticulos] = useState([]);

  const [cambiarPrecio, setCambiarPrecio] = useState(false);
  const [AbrirEditar, setAbrirEditar] = useState(false);
  const [idEdit, setIdEdit] = useState("");
  const [articulosSurtido, setArticulosSurtido] = useState([]);
  const [selectedClienteEdit, setSelectedClienteEdit] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);
  const [iva, setIva] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [descuento, setDescuento] = useState("");
  const [idPedidoEdit, setIdPedidoEdit] = useState("");
  const [totalGeneralAnterior, setTotalGeneralAnterior] = useState(0);
  const [fechaSurtido, setFechaSurtido] = useState("");
  const [areaSurtido, setAreaSurtido] = useState("");
  const [nombreClienteEdit, setNombreClienteEdit] = useState("");
  const [numeroSurtido, setNumeroSurtido] = useState("");
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_cantidad_edit, setTotalCantidadEdit] = useState(0);

  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());

  const [selectedArea, setSelectedArea] = useState(AREAS_CEDIS);
  const [pago, setPago] = useState(false);
  const [efectivo, setEfectivo] = useState(0);
  const [tdc, setTDC] = useState(0);
  const [transfer, setTransfer] = useState(0);
  const [fechaTransfer, setFechaTransfer] = useState("NA");
  const [referencia, setReferencia] = useState("NA");
  const [destino, setDestino] = useState("NA");
  const [terminal, setTerminal] = useState("Tarjeta");
  const [saldo, setSaldo] = useState(0);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      total: 0,
      costoUnitario: 0,
      idInv: "",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
        .get(URL_COLABORADORES, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allColaboradores = response.data;
          setColaboradores(allColaboradores);
        })
        .catch((err) => {
          console.log(err);
        });

    axios
      .get(URL_DEPARTAMENTOS_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_INVENTARIOS}DisponibleAlmacen/${AREAS_CEDIS}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useMemo(() => {
    axios
      .get(`${URL_SURTIDOS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allSurtidos = response.data;
        // Array para pagination
        let arrayTabla = allSurtidos
          .sort((a, b) => (a.idSurtido > b.idSurtido ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              is_active: a.is_active,
              fecha: a.fecha,
              hora: moment(a.createdAt).utcOffset("-0600").format("HH:mm:ss"),
              user: a.user[0].nombre + " " + a.user[0].apellido,
              idUser: a.user[0]._id,
              pedidos: a.pedidos[0].idPedido,
              idPedido: a.pedidos[0]._id,
              clientes: a.clientes[0].nombre_comercial,
              codigoClientes: a.clientes[0].codigo,
              idClientes: a.clientes[0]._id,
              colaboradores: a.colaboradores[0].nombre,
              idColaborador: a.colaboradores[0]._id,
              observaciones: a.observaciones,
              observacionesPedido: a.pedidos[0].observaciones,
              numero: a.idSurtido,
              comisionTDC: a.comisionTDC,
              comisionFlete: a.comisionFlete,
              impuestos: a.impuestos,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              total_cantidad: a.total_cantidad,
              calle: a.clientes[0].calle,
              numero_ext: a.clientes[0].numero_ext,
              numero_int: a.clientes[0].numero_int,
              pais: a.clientes[0].pais,
              estado: a.clientes[0].estado,
              ciudad: a.clientes[0].ciudad,
              delegacion: a.clientes[0].delegacion,
              colonia: a.clientes[0].colonia,
              cp: a.clientes[0].cp,
              paqueteria: a.pedidos[0].paqueterias[0].name,
              idPaqueteria: a.pedidos[0].paqueterias[0]._id,
              RFC: a.clientes[0].RFC,
              area: a.pedidos[0].areas[0].name,
              idAreaSurtido: a.areas[0]._id,
              descuento: a.descuento,
              condicionesPago: a.cond_pago,
              vendedor: a.pedidos[0].colaboradores[0].nombre,
              telefono: a.clientes[0].telefono,
              idDepartamento: a.clientes[0].departamentosClientes[0]._id,
              departamentoName: a.clientes[0].departamentosClientes[0].name,
              creado:
                a.pedidos[0].creado[0].nombre +
                " " +
                a.pedidos[0].creado[0].apellido,
              fechaHora:
                moment(a.createdAt).utcOffset("-0600").format("DD-MM-YYYY") +
                " " +
                moment(a.createdAt).utcOffset("-0600").format("HH:mm:ss"),
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  const ListaSurtidos = comments.sort((a, b) =>
    a.idSurtido > b.idSurtido ? 1 : -1
  );

  function PDFTabla() {
    let totalTotal = 0;
    const data = ListaSurtidos.map((a) => {
      totalTotal = totalTotal + a.total_general;
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)&&
        (selectedColaborador == 0 || selectedColaborador == a.idColaborador)
      ) {
        return [
          a.numero,
          a.fecha,
          a.clientes,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({ compressPdf: true });
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 155, 15, 25, 25);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Importe"]],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(totalTotal),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Notas.pdf`);
  }

  function excel() {
    const dataExcel = ListaSurtidos.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)&&
        (selectedColaborador == 0 || selectedColaborador == a.idColaborador)
      ) {
        return {
          Activa: a.is_active,
          Numero: a.numero,
          Fecha: a.fecha,
          Cliente: a.clientes,
          Pedido: a.pedidos,
          Almacen: a.area,
          Vendedor: a.vendedor,
          Departamento: a.departamentoName,
          CondPago: a.condicionesPago,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoNotas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoNotas",
        sheetFilter: [
          "Activa",
          "Numero",
          "Fecha",
          "Cliente",
          "Pedido",
          "Almacen",
          "Vendedor",
          "Departamento",
          "CondPago",
          "Total",
        ],
        sheetHeader: [
          "Activa",
          "Numero",
          "Fecha",
          "Cliente",
          "Pedido",
          "Almacen",
          "Vendedor",
          "Departamento",
          "CondPago",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let totalTotal = 0;
    const data = ListaSurtidos.map((a) => {
      totalTotal = totalTotal + a.total_general;
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes)&&
        (selectedColaborador == 0 || selectedColaborador == a.idColaborador)
      ) {
        return [
          a.numero,
          a.fecha,
          a.clientes,
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(a.total_general),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({ compressPdf: true });
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 5, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 155, 15, 25, 25);
    doc.text(`Listado de Notas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Cliente", "Importe"]],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(totalTotal),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Notas",
          email: mailTo,
          fileName: "ListadoNotas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Notas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function cancelarNota(idNota) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara la Nota!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${URL_SURTIDOS}Cancel/${idNota}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            Swal.fire("Good job!", "Enviado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  async function PDFOCTicket(
    fecha,
    colaborador,
    clientes,
    numero,
    idPDFOC,
    comisionTDC,
    comisionFlete,
    impuestos,
    areaSurtido,
    descuento,
    // descuentoNumero,
    condPago,
    idAreas
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosVenta = response.data;
        let arrayTabla = allArticulosVenta
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              cantidad: a.cantidad,
              precio: a.precio,
              total: a.precio * a.cantidad,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let totalKG = dataFinal.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);

        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);

        let cajas = dataFinal.map((c) => parseFloat(c.cajas));
        let TC = cajas.reduce((t, total, index) => t + total, 0);

        let LA = dataFinal.length * 1.5;
        let largoTotal = LA + 4 + 13;

        let totalParcial = 0;

        let subTotalTabla = 0;
        let total_general_tabla = 0;
        let iva_tabla = 0;
        let impuesto_tabla = impuestos;

        const data = dataFinal
          .sort((a, b) => (a.articulos > b.articulos ? 1 : -1))
          .map((a) => {
            totalParcial = a.cantidad * a.precio;
            subTotalTabla = subTotalTabla + totalParcial;
            return [
              a.articulos,
              new Intl.NumberFormat("en-US").format(a.cajas),
              // a.piezasPorCaja,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.precio),
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.total),
            ];
          });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

        iva_tabla = (subTotalTabla * impuesto_tabla) / 100;
        total_general_tabla =
          subTotalTabla + comisionTDC + comisionFlete + iva_tabla;

        const doc = new jsPDF("p", "cm", [11, largoTotal]);
        var base64 = qrcode.toDataURL(idPDFOC);
        var img3 = new Image();
        img3.src = base64;

        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";

        doc.addImage(img2, "png", 5, 1, 2, 2);

        doc.setFontSize(6).setFont(undefined, "bold");

        doc.text(`HOLLANDER BH`, 0.3, 1);
        doc.text(`RFC HBH190522NDA`, 0.3, 1.3);
        doc.text(`CALLEJON DE MIXCALCO 6 LOCAL 3`, 0.3, 1.6);
        doc.text(`CENTRO (Area 3), Del Cuauhtemoc`, 0.3, 1.9);
        doc.text(`CMX, CP: 06020`, 0.3, 2.2);
        doc.text(`Tel: 55-5542-5297`, 0.3, 2.5);
        doc.text(`www.hollandermexico.com`, 0.3, 2.8);

        doc.text(`Fecha: ${fecha}`, 0.3, 3.5);
        doc.text(`Nota: ${numero}`, 0.3, 3.8);
        doc.text(
          `Piezas: ${new Intl.NumberFormat("en-US").format(
            TK
          )} / Cajas ${TC.toFixed(2)}`,
          0.3,
          4.1
        );
        doc.text(`Cliente: ${clientes}`, 0.3, 4.4);
        doc.text(`Vendedor: ${colaborador}`, 0.3, 4.7);
        // doc.text(`Atencian al Cliente: 55-2106-8225`, 0.3, 4);

        var img3 = new Image();
        img3.src = process.env.PUBLIC_URL + "logoWA.png";
        doc.addImage(img3, "png", 4.9, 3.2, 0.5, 0.5);
        var img4 = new Image();
        img4.src = process.env.PUBLIC_URL + "logoFB.png";
        doc.addImage(img4, "png", 4.9, 3.8, 0.5, 0.5);
        var img5 = new Image();
        img5.src = process.env.PUBLIC_URL + "logoIG.png";
        doc.addImage(img5, "png", 4.9, 4.4, 0.5, 0.5);

        doc.text(`5614826826`, 5.5, 3.5);
        doc.text(`@hollandermexico`, 5.5, 4.1);
        doc.text(`@hollandermexico`, 5.5, 4.7);

        doc.autoTable({
          head: [
            [
              "Articulo",
              "Cajas",
              // "PPC",
              "Cant",
              "Precio",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
          styles: {
            fontSize: 8,
          },
          startY: 5.3,
          theme: "plain",
          margin: { left: 0.3, right: 0.7 },
          foot: [
            [
              // "",
              "",
              "",
              {
                content: "Total Cajas",
                colSpan: 2,
                styles: { halign: "left" },
              },
              {
                content: new Intl.NumberFormat("en-US").format(TC),
                colSpan: 1,
              },
            ],
            [
              "",
              // "",
              "",
              { content: "SubTotal", colSpan: 2, styles: { halign: "left" } },
              {
                content: new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(subTotalTabla),
                colSpan: 1,
              },
            ],
            // [
            //   "",
            //   "",
            //   "",
            //   { content: `Descuento ${descuento}%`, colSpan: 2, styles: { halign: 'left' } },
            //   { content: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(descuentoNumero), colSpan: 1},
            // ],
            [
              "",
              // "",
              "",
              { content: `Comision`, colSpan: 2, styles: { halign: "left" } },
              {
                content: new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(iva_tabla),
                colSpan: 1,
              },
            ],
            [
              "",
              // "",
              "",
              {
                content: "Comision TDC",
                colSpan: 2,
                styles: { halign: "left" },
              },
              {
                content: new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(comisionTDC),
                colSpan: 1,
              },
            ],
            [
              "",
              "",
              // "",
              { content: "Flete", colSpan: 2, styles: { halign: "left" } },
              {
                content: new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(comisionFlete),
                colSpan: 1,
              },
            ],
            [
              "",
              "",
              // "",
              { content: "Total", colSpan: 2, styles: { halign: "left" } },
              {
                content: new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(total_general_tabla),
                colSpan: 1,
              },
            ],
          ],
          showFoot: "lastPage",
        });
        // doc.text(`*Pendiente por Facturar*`, 20, doc.autoTable.previous.finalY + 20);
        // if(condPago == "Credito"){
        // doc.text(`Firma: ________________`, 20, doc.autoTable.previous.finalY + 25);
        // }

        var lines = doc.splitTextToSize(`Firma:______________________________`);
        doc.text(0.3, doc.autoTable.previous.finalY + 1.5, lines);
        var lines2 = doc.splitTextToSize(`*** Pendiente por Facturar ***`);
        doc.text(0.3, doc.autoTable.previous.finalY + 0.5, lines2);

        doc.save(`Nota-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function PDFOC(
    fecha,
    idClientes,
    clientes,
    observaciones,
    observacionesPedido,
    numero,
    idPDFOC,
    user,
    pedidos,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    calle,
    numero_ext,
    numero_int,
    pais,
    estado,
    ciudad,
    delegacion,
    colonia,
    cp,
    RFC,
    paqueteria,
    area,
    codigoClientes,
    vendedor,
    telefono,
    creado,
    fechaHora,
    descuento
  ) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a.inventarios[0]._id,
              articulos: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              cantidad: a.cantidad,
              idArticulo: a.articulos[0]._id,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              precio: a.precio,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            cantidad: 0,
            cajas: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].cajas += item.cajas;
          groups[val].articulos = item.articulos;
          groups[val].precio = item.precio;
          groups[val].piezasPorCaja = item.piezasPorCaja;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        let totalMts = agrupadoValues.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);

        let TC = agrupadoValues.map((c) => parseFloat(c.cajas));
        let TTC = TC.reduce((t, total, index) => t + total, 0);

        let temp1 = (subTotal * descuento) / 100;

        let totalParcial = 0;

        const data = agrupadoValues
          .sort((a, b) => (a.articulos > b.articulos ? 1 : -1))
          .map((a) => {
            totalParcial = a.cantidad * a.precio;
            return [
              a.articulos,
              new Intl.NumberFormat("en-US").format(a.cajas.toFixed(2)),
              a.piezasPorCaja,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(a.precio),
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(totalParcial),
            ];
          });

        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF({ compressPdf: true });
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 8, 15, 18, 5);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 10, 21, 30, 30);

        var img3 = new Image();
        img3.src = process.env.PUBLIC_URL + "logoWA.png";
        doc.addImage(img3, "png", 140, 40, 5, 5);
        var img4 = new Image();
        img4.src = process.env.PUBLIC_URL + "logoFB.png";
        doc.addImage(img4, "png", 140, 47, 5, 5);
        var img5 = new Image();
        img5.src = process.env.PUBLIC_URL + "logoIG.png";
        doc.addImage(img5, "png", 140, 54, 5, 5);

        let alto = 5;

        doc.setFontSize(14);
        doc.text(`HOLLANDER BH`, 50, alto + 15);
        doc.setFontSize(10);
        doc.text(`RFC HBH190522NDA`, 50, alto + 20);
        doc.text(`CALLEJON DE MIXCALCO 6 LOCAL 3`, 50, alto + 25);
        doc.text(`CENTRO (Area 3), Del Cuauhtemoc`, 50, alto + 30);
        doc.text(`CMX, CP: 06020`, 50, alto + 35);
        doc.text(`Tel: 55-5542-5297`, 50, alto + 40);
        doc.text(`www.hollandermexico.com`, 50, alto + 45);

        doc.setFontSize(10);
        doc.text(`Fecha:`, 140, alto + 15);
        doc.text(`${fecha}`, 160, alto + 15);
        doc.text(`Nota:`, 140, alto + 20);
        doc.text(`${numero}`, 160, alto + 20);
        doc.text(`Paqueteria:`, 140, alto + 25);
        doc.text(`${paqueteria}`, 160, alto + 25);
        doc.text(`Ejecutivo:`, 140, alto + 30);
        doc.text(`${vendedor}`, 160, alto + 30);

        doc.text(`5614826826`, 147, alto + 39);
        doc.text(`@hollandermexico`, 147, alto + 45);
        doc.text(`@hollandermexico`, 147, alto + 52);

        // doc.setFontSize(8);
        // doc.text(`Creado por: ${creado}`, 120, alto + 32);
        // doc.text(`Fecha creado: ${fechaHora}`, 120, alto + 37);

        doc.text(`Facturado a: ${clientes}`, 10, alto + 55);
        // doc.text(`RFC: ${RFC}`, 10, alto + 37);
        // doc.text(`Direccion de Envio: ${calle} #${numero_ext} Int. ${numero_int}`, 10, alto + 47);
        // doc.text(`Telefono: ${telefono}`, 10, alto + 52);
        // doc.text(`Edo: ${estado}, Ciudad: ${ciudad}, CP: ${cp}`, 10, alto + 57);
        // doc.text(`Del: ${delegacion}, Col: ${colonia}`, 10, alto + 62);

        // doc.setFontSize(10);
        // doc.text(`Paqueteria: ${paqueteria}`, 120, alto + 50);
        // doc.text(`Almacen: ${area}`, 120, alto + 57);

        doc.autoTable({
          head: [["Articulo", "Cajas", "PPC", "Cantidad", "Precio", "Total"]],
          body: dataPDFLimpia,
          styles: {
            fontSize: 10,
            overflow: "linebreak",
            columnWidth: "wrap",
            // font: 'arial',
            overflowColumns: "linebreak",
          },
          headStyles: {
            fillColor: [3, 165, 252],
            lineWidth: 0,
          },
          margin: { left: 3, right: 5 },
          startY: alto + 60,
          theme: "plain",
          foot: [
            [
              "",
              new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
              "",
              new Intl.NumberFormat("en-US").format(TM) + " Piezas",
              "Sub Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(subTotal.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              `Comision`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(iva.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              "Comision TDC",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionTDC.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              "Flete",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(comisionFlete.toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              `Desc ${descuento}%`,
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format((-temp1).toFixed(2)),
            ],
            [
              "",
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(total_general.toFixed(2)),
            ],
          ],
          showFoot: "lastPage",
        });
        var lines = doc.splitTextToSize(
          `Observaciones ${observacionesPedido}`,
          200
        );
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);
        var lines2 = doc.splitTextToSize(`*** Pendiente por Facturar ***`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 15, lines2);

        doc.save(`Nota-${numero}.pdf`);
        //   }
        // });
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Hora", field: "hora", sortable: true },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Pedido", field: "pedidos", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Departamento", field: "departamentoName", sortable: true },
    { name: "Cond. Pago", field: "condicionesPago", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Detalles", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.departamentoName
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.condicionesPago
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.pedidos.toString().includes(search) ||
          comment.numero.toString().includes(search) ||
          comment.total_general.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaborador.includes(selectedColaborador)
      );
    }
    if (selectedUser) {
      computedComments = computedComments.filter((e) =>
        e.idUser.includes(selectedUser)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    if (selectedDepartamento) {
      computedComments = computedComments.filter((e) =>
        e.idDepartamento.includes(selectedDepartamento)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "pedidos" &&
      sorting.field != "numero" &&
      sorting.field != "total_general"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "pedidos" ||
        sorting.field == "numero" ||
        sorting.field == "total_general")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "pedidos" ||
        sorting.field == "numero" ||
        sorting.field == "total_general")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    selectedUser,
    selectedFechaInicio,
    selectedFechaFin,
    selectedDepartamento,
  ]);

  function EditArticulos(
    idOC,
    numero,
    comisionTDC,
    comisionFlete,
    impuestos,
    subTotal,
    iva,
    total_general,
    total_cantidad,
    descuento,
    areaSurtido,
    clientes,
    idClientes,
    idPedido,
    fecha
  ) {
    setComisionTDC(comisionTDC);
    setComisionFlete(comisionFlete);
    setSelectedImpuesto(impuestos);
    setDescuento(descuento);
    setIva(iva.toFixed(2));
    setTotalGeneralEdit(total_general);
    setTotalGeneralAnterior(total_general);
    setTotalCantidadEdit(total_cantidad);
    setSubTotal(subTotal);
    setAreaSurtido(areaSurtido);
    setNombreClienteEdit(clientes);
    setSelectedClienteEdit(idClientes);
    setNumeroSurtido(numero);
    setIdPedidoEdit(idPedido);
    setFechaSurtido(fecha);

    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
        costoUnitario: 0,
        idInv: "",
      },
    ]);
    setIdEdit(idOC);

    axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${idOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0]._id,
              codigo: a.articulos[0].codigo + " - " + a.articulos[0].nombre,
              cantidad: a.cantidad,
              cantidadAnterior: a.cantidad,
              precio: a.precio,
              total: a.precio * a.cantidad,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              cajas: a.cantidad / a.articulos[0].piezasPorCaja,
              inventarios: a.inventarios[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosSurtido(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
    setAbrirEditar(true);
  }

  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e != 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }

    let tg =
      (parseFloat(e) * subTotal) / 100 + subTotal + comisionTDC + comisionFlete;
    setTotalGeneralEdit(tg);
  }

  function jalaComisionTDC(e) {
    setComisionTDC(parseFloat(e));
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (selectedImpuesto * subTotal) / 100 +
        subTotal +
        parseFloat(e) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionFlete(e) {
    setComisionFlete(parseFloat(e));
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (selectedImpuesto * subTotal) / 100 +
        subTotal +
        parseFloat(comisionTDC) +
        parseFloat(e)
    );
  }

  const handleChangeInputCajasExistentes = (id, event) => {
    const newArticulosSurtido = articulosSurtido.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosSurtido(newArticulosSurtido);
    ActualizaTotales();
  };

  const handleChangeInputCantidadExistentes = (id, event) => {
    const newArticulosSurtido = articulosSurtido.map((i) => {
      if (id === i.id) {
        i.cantidad = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosSurtido(newArticulosSurtido);
    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantidad = event.target.value;
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
        costoUnitario: 0,
        idInv: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let KG = articulosSurtido.concat(values);
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TK);

    let importesExistentes = articulosSurtido.map((c) => parseFloat(c.total));
    let importesNuevos = values.map((c) => parseFloat(c.total));
    let importes = importesExistentes.concat(importesNuevos);
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(
      (selectedImpuesto * TG) / 100 +
        TG +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );
  };

  const handleChangeInputPrecio = (id, value) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.precio = value;
        let totalParcial = i.cantidad * value;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let activos2 = articulosSurtido.concat(inputFields);
    let cantidadActivos = activos2.map((c) => parseFloat(c.cantidad));
    let TA = cantidadActivos.reduce((t, total, index) => t + total, 0);
    setTotalCantidadEdit(TA);

    let totalActivos = activos2.map((c) => parseFloat(c.total));
    let TG = totalActivos.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG.toFixed(2));

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneralEdit(
      (selectedImpuesto * TG) / 100 +
        TG +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );
  }

  const handleChangeInputExistentesPrecio = (id, event) => {
    const newArticulosSurtido = articulosSurtido.map((i) => {
      if (id === i.id) {
        i.precio = event;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setArticulosSurtido(newArticulosSurtido);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inputFields.filter((e) => e.articulos == event.target.value).length > 0 ||
      articulosSurtido.filter((e) => e.articulos == event.target.value).length >
        0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      articulos.map((a) => {
        if (a.idArticulo == event.target.value) {
          let idArticulo = a.idArticulo;
          let piezasPorCaja = a.piezasPorCaja;
          let precio = a.lista1;
          let costoUnitario = a.costoUnitario;
          let idInv = a.idInv;
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            precio,
            costoUnitario,
            idInv
          );
        }
      });
    }
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    costoUnitario,
    idInv
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.costoUnitario = costoUnitario;
        i.idInv = idInv;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const saveSurtido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = inputFields.length + articulosSurtido.length;
    try {
      await axios
        .patch(
          `${URL_SURTIDOS}EditVenta/${idEdit}`,
          {
            total_generalAnterior: totalGeneralAnterior,
            total_general: parseFloat(total_general_edit).toFixed(2),
            total_cantidad: total_cantidad_edit,
            comisionTDC,
            comisionFlete,
            subTotal: parseFloat(subTotal).toFixed(2),
            iva: parseFloat(iva).toFixed(2),
            impuestos: selectedImpuesto,
            clientes: selectedClienteEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(async() => {
          let t = parseFloat(efectivo) + parseFloat(tdc) + parseFloat(transfer)
          if(pago){
            await axios
            .post(
              URL_ABONOS,
              {
                fecha: endDate,
                total: t,
                areas: selectedArea,
                clientes: selectedClienteEdit,
                surtidos: idEdit,
                efectivo: efectivo,
                tdc: tdc,
                transfer: transfer,
                fechaTransfer: fechaTransfer,
                referencia: referencia,
                cortesia: 0,
                nomina:0,
                destino: "Transfer",
                terminal: "Tarjeta",
                codigoAgrupado,
                comisionTDC:0,
                concepto:"Abono",
                observaciones: "NA",
                condPago: "Contado",
                anticipo: "No",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
          }

          inputFields.map((a) => {
            if (a.articulos != "" && a.cantidad != 0) {
              axios
                .post(
                  `${URL_ARTICULOS_SURTIDOS}AgregarVenta`,
                  {
                    surtidos: idEdit,
                    articulos: a.articulos,
                    cantidad: parseFloat(a.cantidad),
                    precio: parseFloat(a.precio),
                    total: parseFloat(a.total).toFixed(2),
                    clientes: selectedClienteEdit,
                    pedidos: idPedidoEdit,
                    fecha: fechaSurtido,
                    inventarios: a.idInv,
                    costoUnitario: a.costoUnitario,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalPedido = totalPedido - 1;
                  if (totalPedido == 0) {
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Editar Venta",
                        detalle: `${numeroSurtido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                });
            } else {
              totalPedido = totalPedido - 1;
              if (totalPedido == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Venta",
                    detalle: `${numeroSurtido}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            }
          });
          articulosSurtido.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_SURTIDOS}EditVenta/${a.id}`,
                {
                  cantidadAnterior: parseFloat(a.cantidadAnterior),
                  cantidad: parseFloat(a.cantidad),
                  precio: parseFloat(a.precio),
                  total: parseFloat(a.total).toFixed(2),
                  inventarios: a.inventarios,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalPedido = totalPedido - 1;
                if (totalPedido == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Editar Surtido",
                      detalle: `${numeroSurtido}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function jalaEfectivo(e) {
    setEfectivo(e);
    setSaldo(
      ( total_general_edit - totalGeneralAnterior - e - tdc - transfer).toFixed(2)
      // - cortesia
    );
  }

  function jalaTransfer(e) {
    setTransfer(e);
    if (e > 0) {
      setDestino("");
    } else {
      setDestino("NA");
    }
    setSaldo(( total_general_edit - totalGeneralAnterior - efectivo - tdc - e).toFixed(2));
  }

  function jalaTDC(e) {
    setTDC(e);
    if (e > 0) {
      setTerminal("");
    } else {
      setTerminal("NA");
    }
    setSaldo(( total_general_edit - totalGeneralAnterior - efectivo - e - transfer).toFixed(2));
  }

  let totalImporteTabla = 0;

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_ventas ? (
        <div className="card col-12">
          {!AbrirEditar ? (
            <>
              <Row>
                <Col md={8}>
                  <Button
                    size="sm"
                    href="/MenuAdmin"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                <ModalHeader toggle={toggleMail}>
                  <h4>Enviar Listado de Notas</h4>
                </ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Email</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={mailTo}
                    required
                    onChange={(e) => {
                      setMailTo(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={enviaMail}>
                    Enviar
                  </Button>
                </ModalFooter>
              </Modal>
              <h3 align="center">Ventas</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Inicio</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Fin</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>Clientes</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedCliente}
                    onChange={(e) => {
                      setSelectedCliente(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {clientes
                      .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={2}>
                  <Label>Vendedor</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedColaborador}
                    onChange={(e) => {
                      setSelectedColaborador(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {colaboradores
                      .sort((a, b) =>
                        a.nombre > b.nombre ? 1 : -1
                      )
                      .map((a) => {
                        return (
                          <option value={a._id}>{a.nombre}</option>
                        );
                      })}
                  </Input>
                </Col>
                <Col md={2}>
                  <Label>Departamento</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedDepartamento}
                    onChange={(e) => {
                      setSelectedDepartamento(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {departamentos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>
              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                  {commentsData.map((a) => {
                    if (a.is_active == "No") {
                      return (
                        <tr style={{ backgroundColor: "#ed8c8c" }}>
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.hora}</td>
                          <td>{a.clientes}</td>
                          <td>{a.pedidos}</td>
                          <td>{a.area}</td>
                          <td>{a.vendedor}</td>
                          <td>{a.departamentoName}</td>
                          <td>{a.condicionesPago}</td>
                          <td></td>
                          <td>CANCELADA</td>
                        </tr>
                      );
                    } else {
                      totalImporteTabla = totalImporteTabla + a.total_general;
                      return (
                        <tr>
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.hora}</td>
                          <td>{a.clientes}</td>
                          <td>{a.pedidos}</td>
                          <td>{a.area}</td>
                          <td>{a.vendedor}</td>
                          <td>{a.departamentoName}</td>
                          <td>{a.condicionesPago}</td>
                          <td>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                            }).format(a.total_general)}
                          </td>
                          <td>
                            <>
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) =>
                                  PDFOC(
                                    a.fecha,
                                    a.idClientes,
                                    a.clientes,
                                    a.observaciones,
                                    a.observacionesPedido,
                                    a.numero,
                                    a._id,
                                    a.user,
                                    a.pedidos,
                                    a.comisionTDC,
                                    a.comisionFlete,
                                    a.impuestos,
                                    a.subTotal,
                                    a.iva,
                                    a.total_general,
                                    a.calle,
                                    a.numero_ext,
                                    a.numero_int,
                                    a.pais,
                                    a.estado,
                                    a.ciudad,
                                    a.delegacion,
                                    a.colonia,
                                    a.cp,
                                    a.RFC,
                                    a.paqueteria,
                                    a.area,
                                    a.codigoClientes,
                                    a.vendedor,
                                    a.telefono,
                                    a.creado,
                                    a.fechaHora,
                                    a.descuento
                                  )
                                }
                              >
                                <i class="far fa-file-pdf"></i>
                              </Button>
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) =>
                                  PDFOCTicket(
                                    a.fecha,
                                    a.vendedor,
                                    a.clientes,
                                    a.numero,
                                    a._id,
                                    a.comisionTDC,
                                    a.comisionFlete,
                                    a.impuestos,
                                    a.area,
                                    a.descuento,
                                    // a.descuentoNumero,
                                    a.condicionesPAgo,
                                    a.idAreas
                                  )
                                }
                              >
                                <i class="fas fa-ticket-alt"></i>
                              </Button>

                              {user.editar_ventas ? (
                                <>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      EditArticulos(
                                        a._id,
                                        a.numero,
                                        a.comisionTDC,
                                        a.comisionFlete,
                                        a.impuestos,
                                        a.subTotal,
                                        a.iva,
                                        a.total_general,
                                        a.total_cantidad,
                                        a.descuento,
                                        a.idAreaSurtido,
                                        a.clientes,
                                        a.idClientes,
                                        a.idPedido,
                                        a.fecha
                                      )
                                    }
                                  >
                                    <i class="fas fa-cart-plus"></i>
                                  </Button>

                                  <Button
                                    size="sm"
                                    className="btn"
                                    color="danger"
                                    onClick={(e) => cancelarNota(a._id)}
                                  >
                                    <i class="fas fa-ban"></i>
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    disabled
                                  >
                                    <i class="fas fa-cart-plus"></i>
                                  </Button>

                                  <Button
                                    size="sm"
                                    className="btn"
                                    color="danger"
                                    disabled
                                  >
                                    <i class="fas fa-ban"></i>
                                  </Button>
                                </>
                              )}
                            </>
                          </td>
                        </tr>
                      );
                    }
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="negrita" align="center">
                      TOTAL
                    </td>
                    <td className="negrita">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(totalImporteTabla)}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          ) : (
            <div className="card container col-md-12">
              <h4>Editar Venta {numeroSurtido}</h4>
              <h4>Cliente: {nombreClienteEdit}</h4>
              <Form onSubmit={saveSurtido}>
                <Row>
                  <Col md={1}>
                    <Label>TDC</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={comisionTDC}
                      className={`form-control`}
                      onValueChange={(value) => {
                        jalaComisionTDC(value);
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Flete</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={comisionFlete}
                      className={`form-control`}
                      onValueChange={(value) => {
                        jalaComisionFlete(value);
                      }}
                    />
                  </Col>
                  <Col md={1}>
                    <Label>Impuestos %</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={selectedImpuesto}
                      onChange={(e) => {
                        calculaImpuesto(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Sub Total</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={subTotal}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>IVA</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={iva}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={2}>
                    <Label>Total</Label>
                    <CurrencyInput
                      intlConfig={{ locale: "en-US", currency: "USD" }}
                      prefix="$"
                      placeholder="Ingresa una cantidad"
                      defaultValue={0}
                      decimalsLimit={2}
                      value={total_general_edit}
                      disabled
                      className={`form-control`}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>Piezas</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Piezas"
                      value={total_cantidad_edit}
                      required
                      disabled
                    />
                  </Col>

                  {/* <Col md={1}>
                  <Label></Label>
                  <Button
                    size="sm"
                    className="btn"
                    color="info"
                    onClick={(e) => toggleCambiaPrecio()}
                  >
                    Cambiar Precio <i class="fas fa-dollar-sign"></i>
                  </Button>
                </Col> */}
                </Row>
                <br />
                <Row>
                  <Col md={2}>
                    <Label className="mr-sm-2">Articulos</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cajas</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Pzas Caja</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Cantidad</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Precio</Label>
                  </Col>
                  <Col md={1}>
                    <Label className="mr-sm-2">Total</Label>
                  </Col>
                </Row>

                {articulosSurtido
                  .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                  .map((ao) => (
                    <div key={ao.id}>
                      <Row>
                        <Col md={2}>
                          <Input
                            name="articulos"
                            type="select"
                            value={ao.articulos}
                            required
                            disabled
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                              .map((a) => {
                                return (
                                  <option value={a.idArticulo}>
                                    {a.codigo} {a.nombre}
                                  </option>
                                );
                              })}
                          </Input>
                        </Col>

                        {user.cambiaPrecios ? (
                        <Col md={1}>
                          <Input
                            //  bsSize="sm"
                            name="cajas"
                            type="string"
                            value={ao.cajas}
                            onChange={(event) =>
                              handleChangeInputCajasExistentes(ao.id, event)
                            }
                          ></Input>
                        </Col>
                         ) : (
                          <Col md={1}>
                            <Input
                            //  bsSize="sm"
                            name="cajas"
                            type="string"
                            value={ao.cajas}
                            disabled
                          ></Input>
                          </Col>
                        )}

                        <Col md={1}>
                          <Input
                            //  bsSize="sm"
                            name="piezasPorCaja"
                            type="string"
                            value={ao.piezasPorCaja}
                            disabled
                          ></Input>
                        </Col>

                        {user.cambiaPrecios ? (
                        <Col md={1}>
                          <Input
                            // bsSize="sm"
                            name="cantidad"
                            type="number"
                            min="0"
                            step="any"
                            placeholder="Cantidad"
                            value={ao.cantidad}
                            required
                            onChange={(event) =>
                              handleChangeInputCantidadExistentes(ao.id, event)
                            }
                          />
                        </Col>
                         ) : (
                          <Col md={1}>
                            <Input
                            // bsSize="sm"
                            name="cantidad"
                            type="number"
                            min="0"
                            step="any"
                            placeholder="Cantidad"
                            value={ao.cantidad}
                            disabled
                          />
                          </Col>
                        )}

                        {user.cambiaPrecios ? (
                          <Col md={1}>
                            <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="precio"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={ao.precio}
                              className={`form-control`}
                              onValueChange={(value) =>
                                handleChangeInputExistentesPrecio(ao.id, value)
                              }
                            />
                          </Col>
                        ) : cambiarPrecio ? (
                          <Col md={1}>
                            <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="precio"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={ao.precio}
                              className={`form-control`}
                              onValueChange={(value) =>
                                handleChangeInputExistentesPrecio(ao.id, value)
                              }
                            />
                          </Col>
                        ) : (
                          <Col md={1}>
                            <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="precio"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={ao.precio}
                              className={`form-control`}
                              disabled
                            />
                          </Col>
                        )}

                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="total"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={ao.total}
                            className={`form-control`}
                            disabled
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                {/* Agregar mas articulos */}

                {inputFields.map((inputField) => (
                  <div key={inputField.id}>
                    <Row>
                      <Col md={2}>
                        <Input
                          // bsSize="sm"
                          name="articulos"
                          type="select"
                          value={inputField.articulos}
                          required
                          onChange={(event) => {
                            BuscaArticulo(inputField.id, event);
                          }}
                        >
                          <option value="0">Selecciona</option>
                          {articulos
                            .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                            .map((a) => {
                              // if(a.disponible >0){
                              return (
                                <option value={a.idArticulo}>
                                  {a.codigo} {a.nombre}
                                </option>
                              );
                              // }
                            })}
                        </Input>
                      </Col>

                      <Col md={1}>
                        <Input
                          //  bsSize="sm"
                          name="cajas"
                          type="string"
                          value={inputField.cajas}
                          onChange={(event) =>
                            handleChangeInputCajas(inputField.id, event)
                          }
                        ></Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          //  bsSize="sm"
                          name="piezasPorCaja"
                          type="string"
                          value={inputField.piezasPorCaja}
                          disabled
                        ></Input>
                      </Col>
                      <Col md={1}>
                        <Input
                          // bsSize="sm"
                          name="cantidad"
                          type="number"
                          min="0"
                          step="any"
                          placeholder="Cantidad"
                          value={inputField.cantidad}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidad(inputField.id, event)
                          }
                        />
                      </Col>

                      {user.cambiaPrecios ? (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={inputField.precio}
                            className={`form-control`}
                            onValueChange={(value) =>
                              handleChangeInputPrecio(inputField.id, value)
                            }
                          />
                        </Col>
                      ) : cambiarPrecio ? (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={inputField.precio}
                            className={`form-control`}
                            onValueChange={(value) =>
                              handleChangeInputPrecio(inputField.id, value)
                            }
                          />
                        </Col>
                      ) : (
                        <Col md={1}>
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="precio"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={inputField.precio}
                            className={`form-control`}
                            disabled
                          />
                        </Col>
                      )}

                      <Col md={1}>
                        <CurrencyInput
                          intlConfig={{ locale: "en-US", currency: "USD" }}
                          prefix="$"
                          name="total"
                          defaultValue={0}
                          decimalsLimit={2}
                          value={inputField.total}
                          className={`form-control`}
                          disabled
                        />
                      </Col>
                      <Col>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          disabled={inputFields.length === 1}
                          onClick={() => handleRemoveFields(inputField.id)}
                          tabindex="-1"
                        >
                          <i class="fas fa-minus"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="info"
                          onClick={handleAddFields}
                          tabindex="-1"
                        >
                          <i class="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
<br />

            <br />
            {totalGeneralAnterior < total_general_edit ? (
              <>
              <Row>
                <h4 className="subMenuTitulo">
                  Total Anterior: {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(totalGeneralAnterior)}
                  </h4>
              </Row>
              
              <Row>
                <h4 className="subMenuTitulo">
                  Total Nuevo: {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(total_general_edit)}
                  </h4>
              </Row>
              
              <Row>
                <h4 className="subMenuTitulo">
                  Diferencia: {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2,
                      }).format(total_general_edit - totalGeneralAnterior)}
                  </h4>
              </Row>
            <Col md={3}>
            <Input
              type="checkbox"
               onChange={(event) => setPago(event.currentTarget.checked)}
              checked={pago}
              // disabled
            />

            <h4 className="subMenuTitulo">Deseas Pagar la Diferencia?</h4>
            </Col>
            </>
             ) : undefined}
           
            {(totalGeneralAnterior < total_general_edit)&&pago ? (
              <>
                <Row>
                  <Col md={1}>
                    <Label>Efectivo</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={efectivo}
                      required
                      onChange={(e) => {
                        jalaEfectivo(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>TDC</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={tdc}
                      required
                      onChange={(e) => {
                        jalaTDC(e.target.value);
                      }}
                    />
                  </Col>



                  <Col md={1}>
                    <Label>Transfer</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={transfer}
                      required
                      onChange={(e) => {
                        jalaTransfer(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={1}>
                    <Label>Referencia</Label>
                        {transfer > 0 ? (
                            <Input
                            // bsSize="sm"
                            type="text"
                            name="referencia"
                            value={referencia}
                            onChange={(e) => {
                              setReferencia(e.target.value);
                            }}
                            />
                          ) : (
                            <Input
                              // bsSize="sm"
                              type="text"
                              name="referencia"
                              value={referencia}
                              disabled
                            />
                          )}
                        </Col>


                  <Col md={1}>
                    <Label>Saldo</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={saldo}
                      required
                      disabled
                    />
                  </Col>
                </Row>
              </>
            ) : undefined}

                {validaBoton ? (
                  <Button type="submit" className="btn btn-success">
                    Guardar
                  </Button>
                ) : (
                  <Button type="submit" className="btn btn-success" disabled>
                    Guardar
                  </Button>
                )}
                <Button
                  onClick={(e) => setAbrirEditar(false)}
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Form>
            </div>
          )}

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      <br />
      {loader}
    </>
  );
}

export default ListadoVentas;
